import React from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import dayjs from 'dayjs';

interface Account {
  name: string;
  email: string;
  status: string;
  licenseExpiry: string; // ライセンス期限
}

const MasterAccountList: React.FC = () => {
  const accounts: Account[] = [
    {
      name: 'マスターアカウント１',
      email: 'master1@example.com',
      status: '有効',
      licenseExpiry: '2024-12-31', // 期限内
    },
    {
      name: 'マスターアカウント２',
      email: 'master2@example.com',
      status: '無効',
      licenseExpiry: '2023-12-31', // 期限切れ
    },
  ];

  const checkLicenseStatus = (expiryDate: string) => {
    const today = dayjs();
    return today.isAfter(dayjs(expiryDate)) ? '期限切れ' : expiryDate;
  };

  return (
    <Container maxWidth="md">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>アカウント名</TableCell>
              <TableCell>メールアドレス</TableCell>
              <TableCell>状態</TableCell>
              <TableCell>ライセンス期限</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account, index) => (
              <TableRow key={index}>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.email}</TableCell>
                <TableCell>{account.status}</TableCell>
                <TableCell>{checkLicenseStatus(account.licenseExpiry)}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" sx={{ mr: 1 }}>
                    編集
                  </Button>
                  <Button variant="contained" color="secondary">
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default MasterAccountList;
