import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Toolbar } from '@mui/material';
import { Home, Person, Settings, Assignment, Inventory, Loyalty, AccountBox, ListAlt } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 300; // サイドメニューの幅を300に設定

const Sidebar: React.FC = () => {
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        
        <ListItem button component={Link} to="/dashboard/master-account">
          <ListItemIcon><AccountBox /></ListItemIcon>
          <ListItemText primary="アカウント発行/Dr" />
        </ListItem>

        <ListItem button component={Link} to="/dashboard/master-account-list">
          <ListItemIcon><ListAlt /></ListItemIcon>
          <ListItemText primary="Dr.一覧" />
        </ListItem>
        
        <Divider />
        <ListItem button component={Link} to="/dashboard/temp-account">
          <ListItemIcon><AccountBox /></ListItemIcon>
          <ListItemText primary="アカウント発行/Clinic" />
        </ListItem>

        <ListItem button component={Link} to="/dashboard/temp-account-list">
          <ListItemIcon><ListAlt /></ListItemIcon>
          <ListItemText primary="Clinic一覧" />
        </ListItem>
        
        
      </List>
    </Drawer>
  );
};

export default Sidebar;
