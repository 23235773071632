import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Card, CardContent, CardActions, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(https://source.unsplash.com/random)',
  backgroundSize: 'cover',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 275,
  padding: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '12px',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}));

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/dashboard');
  };

  return (
    <Root>
      <StyledCard>
        <CardContent>
          <StyledTypography color="textPrimary" gutterBottom>
            機構ログイン
          </StyledTypography>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </CardContent>
        <CardActions>
          <StyledButton 
            variant="contained" 
            color="primary" 
            onClick={handleLogin}
          >
            Login
          </StyledButton>
        </CardActions>
      </StyledCard>
    </Root>
  );
};

export default Login;
