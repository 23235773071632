import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const TempAccount: React.FC = () => {
  const [clinicName, setClinicName] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [personInCharge, setPersonInCharge] = useState<string>('');
  const [loginId, setLoginId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Clinic Name:', clinicName);
    console.log('Contact:', contact);
    console.log('Person in Charge:', personInCharge);
    console.log('Login ID:', loginId);
    console.log('Password:', password);
    // 実際の送信処理はここで行う
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          label="クリニック名"
          variant="outlined"
          fullWidth
          margin="normal"
          value={clinicName}
          onChange={(e) => setClinicName(e.target.value)}
        />
        <TextField
          label="連絡先"
          variant="outlined"
          fullWidth
          margin="normal"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
        />
        <TextField
          label="担当者"
          variant="outlined"
          fullWidth
          margin="normal"
          value={personInCharge}
          onChange={(e) => setPersonInCharge(e.target.value)}
        />
        <TextField
          label="ログインID"
          variant="outlined"
          fullWidth
          margin="normal"
          value={loginId}
          onChange={(e) => setLoginId(e.target.value)}
        />
        <TextField
          label="パスワード"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          作成
        </Button>
      </Box>
    </Container>
  );
};

export default TempAccount;
