import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import dayjs from 'dayjs';

interface Account {
  id: number;
  name: string;
  email: string;
  licenseExpiration: string; // 期限の日付を追加
}

const TempAccountList: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);

  // データを取得する関数
  useEffect(() => {
    // サンプルデータにライセンス期限を追加
    const sampleData: Account[] = [
      { id: 1, name: 'テストアカウント１', email: 'test1@example.com', licenseExpiration: dayjs().add(30, 'day').format('YYYY-MM-DD') },
      { id: 2, name: 'テストアカウント２', email: 'test2@example.com', licenseExpiration: dayjs().add(60, 'day').format('YYYY-MM-DD') },
      { id: 3, name: 'テストアカウント３', email: 'test3@example.com', licenseExpiration: dayjs().add(90, 'day').format('YYYY-MM-DD') },
      { id: 4, name: 'テストアカウント４', email: 'test4@example.com', licenseExpiration: dayjs().add(120, 'day').format('YYYY-MM-DD') },
    ];
    setAccounts(sampleData);
  }, []);

  return (
    <Container maxWidth="lg">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>クリニック名</TableCell>
              <TableCell>連絡先メールアドレス</TableCell>
              <TableCell>ライセンス期限</TableCell> {/* ライセンス期限の列 */}
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.name}</TableCell>
                <TableCell>{account.email}</TableCell>
                <TableCell>{account.licenseExpiration}</TableCell> {/* 期限を表示 */}
                <TableCell>
                  <Button variant="contained" color="primary" sx={{ mr: 1 }}>
                    編集
                  </Button>
                  <Button variant="contained" color="secondary">
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TempAccountList;
